<template>
  <div>
    <Navigation/>
    <v-alert v-if="storeErrorMessage" dense border="top" color="error" dark>
      <div id="storeErrorMessage">storeErrorMessage</div>
      {{ storeErrorMessage }}
    </v-alert>
    <v-alert v-if="cloudErrorMessage" dense border="top" color="error" dark>
      <div id="cloudErrorMessage">cloudErrorMessage</div>
      {{ cloudErrorMessage }}
    </v-alert>
    <v-alert v-if="!cloudConnected" border="top" dense color="info" dark>
      <v-progress-circular indeterminate :color="color"></v-progress-circular>
      {{ selectedLanguage['home_component_text1'] }}
    </v-alert>
    <v-alert v-if="cloudPasswordInput" dense border="top" color="warning" dark>
      {{ selectedLanguage['home_component_text2'] }}<br>
      <v-text-field v-model="cloudPasswordText" :rules="rules.password" clearable type="password" prepend-icon="mdi-lock" outlined :placeholder="selectedLanguage['home_component_text3']"/>
      <v-btn @click="cloudConnect">{{ selectedLanguage['home_component_text4'] }}</v-btn>
    </v-alert>
    <v-alert v-if="!loadedStore" border="top" dense color="info" dark>
      <v-progress-circular indeterminate :color="color"></v-progress-circular>
      {{ selectedLanguage['home_component_text5'] }}
    </v-alert>
    <div class="home" v-else-if="Object.keys(statuses).length === 0 || Object.keys(calcs).length === 0">
      <v-container>
        <v-alert border="right" color="info" dark>
          {{ selectedLanguage['home_component_text6'] }}
        </v-alert>
      </v-container>
    </div>
    <div class="home" v-else>
      <v-container :key="homeKey">
        <v-row dense>
          <v-col v-for="item in statuses" :key="item.calc" cols="12" md="6">
            <div v-if="calcs[item.calc]">
              <v-card :color="color" dark link  v-if="calcs[item.calc]['status']=='a'">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-col @click="openProject(calcs[item.calc].type,item.calc,1)" v-if="calcs[item.calc]" cols="7">
                    <v-card-title class="text-h5" style="max-height: 64px; height: 64px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" >{{ calcs[item.calc].title }}</v-card-title>
                    <v-card-subtitle class="text-subtitle-2">
                      <div style="max-height: 22px; height: 22px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                        {{calcs[item.calc].description}}
                      </div>
                      <div style="max-height: 22px; height: 22px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                        {{selectedLanguage['text_home_1']}} {{item.step}}/8
                      </div>
                      <v-progress-circular v-if="item.status=='Calculating'" indeterminate color="white" :size="15" :width="3"/>
                      <v-icon v-else-if="item.status=='Error'" color="white" :size="15" :width="3">mdi-alert-circle-outline</v-icon>
                      <v-icon v-if="item.status=='Done'" color="white" :size="15" :width="3">mdi-check-outline</v-icon>
                      {{selectedLanguage['text_home_2']}} {{ item.updatedAtFormatted }}
                    </v-card-subtitle>
                  </v-col>
                  <v-avatar @click="openProject(calcs[item.calc].type,item.calc,1)" class="ma-3" size="100" tile style="display: flex; align-self: center; justify-content: center; align-items: center;">
                    <v-img :src="'/img/'+calcs[item.calc].type+'.jpg'"></v-img>
                  </v-avatar>
                  <div style="display: flex; flex-direction: column; justify-content: space-between;">
                    <div style="padding: 5px;">
                      <v-tooltip max-width="600" right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
                            <v-icon @click="archiveCalc(item.calc)">mdi-delete-forever-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ selectedLanguage['help_text_home_1'] }}</span>
                      </v-tooltip>
                    </div>
                    <div v-if="calcs[item.calc].cloud_link != null" style="padding: 5px;">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on">
                            <v-icon @click="cloudLink(calcs[item.calc])" >mdi-cloud-arrow-right-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ selectedLanguage['tooltip_home_btn_text_1'] }}</span>
                      </v-tooltip>
                    </div>
                    <div style="padding: 5px;">
                      <v-tooltip max-width="600" right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
                            <v-icon @click="sendEmail(item, calcs[item.calc])">mdi-help-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ selectedLanguage['help_text_home_2'] }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Navigation from './Navigation'
  import { portuguese } from '../languages/pt-br';
  import { english } from '../languages/en';

  export default {
    name: 'Home',
    computed: {
      ...mapGetters(['isAuthenticated','calcs','statuses','loadedStore','cloudPassword','cloudConnected','statusHome', 'intervalId','profile']),
      selectedLanguage() {
        if(this.profile != undefined){
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        } else {
          return portuguese;
        }
        },
    },
    mounted: async function(){

      // previne de carregar a pagina se colocar apenas o /#
      if( !(localStorage.getItem('vuex')) ){
          this.$router.push({ name: 'login' })
      }

      if(!this.isAuthenticated){
        this.$router.push({ name: 'login' })
      }

      var intervalId = JSON.parse(localStorage.getItem('vuex'))['auth']['intervalId']
      this.clearAtHome()
      window.clearInterval(intervalId)
      if(!this.cloudConnected){
        this.cloudConnect()
      }
      if(!this.loadedStore){
        const res = await this.loadStore()
        if(res==true){
          this.setLoadedStore() 
          this.setStatusHome()
          this.intervalIdFunc(
            window.setInterval(() => {
              this.getStatuses()
            }, 10000)
          )
        }
      }
      this.getCalcs()

      this.clearSRID()
    },
    components: { Navigation },
    data: () => ({
      color: '#0A7373',
      loadingStore: true,
      homeKey: 0,
      storeErrorMessage: undefined,
      cloudErrorMessage: undefined,
      cloudPasswordInput: false,
      cloudPasswordText: undefined,
      rules: {
        password: [
          value => !!value || 'Please enter a password',
          (value) => !!value || 'Please type password.',
          (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
          (value) => (value && value.length > 9 ) || 'minimum 10 characters',
        ]
      },
    }),
    methods: {
      ...mapActions(['setSteps','setBaseLayers','getProfile','getMonetize','getTransactions','getConfigDefaults','getConfigs','getCalcs','getStatuses','getExchange','getSridDict','updateCalc','intervalIdFunc','setLoadedStore','setCloudConnected','loginCloud','setCloudPassword','setStatusHome','clearAtHome']),
      openProject(type,calc_id){
        this.$router.push('/type/'+type+'/'+calc_id)
      },
      sendEmail(param, calcs){
        var email = "support@wwcalc.com"
        var subject = "Solicitação de suporte"
        var message = 'Código do cálculo: ' + param.calc+'\nNome: '+calcs.title+'\nDescrição do problema: Explique o problema ocorrido.'
        var mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
        window.location.href = mailtoLink
      },
      cloudLink(value){
        // window.location.href = value.cloud_link
        window.open(value.cloud_link, '_blank')
      },
      clearSRID(){
        window.localStorage.removeItem('CurrentSRID')
      },
      async archiveCalc(calcId){
        this.storeErrorMessage = undefined
        const res = await this.updateCalc({'id': calcId, 'status': 'i'})
        if(res!=true){
          this.storeErrorMessage = res
        }
        this.homeKey = this.homeKey+1
      },
      async cloudConnect(){
        this.cloudErrorMessage = undefined
        if(this.cloudPasswordText!=undefined){
          this.setCloudPassword(this.cloudPasswordText)
          this.cloudPasswordText = undefined
          this.cloudPasswordInput = false
        }
        const res = await this.loginCloud()
        if(res!=true){
          this.cloudErrorMessage = res
          this.cloudPasswordInput = true
          return false
        }
        this.setCloudConnected()
      },
      async loadStore(){
        this.storeErrorMessage = undefined
        
        try{
          this.setSteps()
          this.getExchange()
          this.setBaseLayers()
          const getProfilePromise = this.getProfile()
          const getMonetizePromise = this.getMonetize()
          const getTransactionsPromise = this.getTransactions()
          const getConfigDefaultsPromise = this.getConfigDefaults()
          const getConfigsPromise = this.getConfigs()
          const getCalcsPromise = this.getCalcs()
          const getStatusesPromise = this.getStatuses()
          const getSridDictPromise = this.getSridDict()

          await Promise.all([
            this.setSteps(), this.getExchange(), this.setBaseLayers(),this.getProfile(), this.getMonetize(), this.getTransactions(), 
            this.getConfigDefaults(), this.getConfigs(), this.getCalcs(), this.getStatuses(), this.getSridDict()
          ])
          // console.log('parte 1')
          let res = await getProfilePromise
          if(res!=true){
            this.storeErrorMessage = res
            return false
          }
          // console.log('parte 2')
          res = await getMonetizePromise
          if(res!=true){
            this.storeErrorMessage = res
            return false
          }
          // console.log('parte 3')
          res = await getTransactionsPromise
          if(res!=true){
            this.storeErrorMessage = res
            return false
          }
          // console.log('parte 4')
          res = await getConfigDefaultsPromise
          if(res!=true){
            this.storeErrorMessage = res
            return false
          }
          // console.log('parte 5')
          res = await getConfigsPromise
          if(res!=true){
            this.storeErrorMessage = res
            return false
          }
          // console.log('parte 6')
          res = await getCalcsPromise
          if(res!=true){
            this.storeErrorMessage = res
            return false
          }
          // console.log('parte 7')
          res = await getStatusesPromise
          if(res!=true){
            this.storeErrorMessage = res
            return false
          }
          // console.log('parte 8')
          res = await getSridDictPromise
          if(res!=true){
            this.storeErrorMessage = res
            return false
          }
          // console.log('parte 9')
        }
        catch{
          this.storeErrorMessage = 'Store unexpected error!'
          this.loadingStore = false
          return false
        }
        this.loadingStore = false        
        return true
      }
    }
  }
</script>

